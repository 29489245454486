import { useMemo } from 'react';
import { useGetFitTechQuestionsQuery } from '../services/mainApi';
import { FitTechQuestion } from '../services/models';


export const useFitTechQuestions = () => {

  const questions = useGetFitTechQuestionsQuery();

  const questionsById = useMemo( () => {
    if ( !questions.data ) return;
    const byIds : Record<string, FitTechQuestion> = {};
    questions.data.forEach( q => byIds[q._id] = q );
    return byIds;
  }, [ questions.data ] );
  
  return questionsById;
};