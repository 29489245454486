import { DateTime } from 'luxon';
import { useState } from 'react';
import { useGetAssessmentsQuery, useGetCompaniesQuery } from '../../../../services/mainApi';
import { DatePicker } from '../DatePicker';
import { Select } from '../Select';
import './SubmissionsFilters.sass';

export interface SubmissionsFiltersValues {
  company?: string,
  assessment?: string,
  fromDate: DateTime | null,
  toDate: DateTime | null
}

export interface FiltersProps {
  onFilter: ( filters: SubmissionsFiltersValues ) => void
}

export const SubmissionsFilters = ( { onFilter } : FiltersProps ) => {

  const [ company, setCompany ] = useState<string>();
  const [ assessment, setAssessment ] = useState<string>();
  const [ fromDate, setFromDate ] = useState<DateTime | null>( null );
  const [ toDate, setToDate ] = useState<DateTime | null>( null );

  const companies = useGetCompaniesQuery();
  const assessments = useGetAssessmentsQuery( { companyId: company }, { 
    skip: !company || company === 'all'
  } );
  
  const onCompanyChange = ( value: string ) => {
    setCompany( value );
    setAssessment( undefined );
  };
  const onAssessmentChange = ( value: string ) => setAssessment( value );
  const onFromDateChange = ( value: DateTime | null ) => value && setFromDate( value );
  const onToDateChange = ( value: DateTime | null ) => value && setToDate( value );

  const onFilterClick = () => {
    onFilter( {
      fromDate, toDate, assessment, company
    } );
  };

  
  return (
    <div className='submissions-filters'>
      <Select
        placeholder='Company'
        options={new Map(
          ( companies.data || [] ).map( company => [ company._id, company.name ] ).concat( [ 
            [ 'all', 'All Companies' ]
          ] ) as [ [string, string] ]
        )}
        onChange={onCompanyChange}
      />
      <Select
        value={assessment === undefined ? null : undefined}
        options={new Map(
          ( company === 'all' ? [] : ( assessments.data || [] ) )
          .map( assessment => [ assessment._id, assessment.role ] ).concat( [
            [ 'all', 'All Assessments' ]
          ] ) as [ [string, string] ]
        )}
        onChange={onAssessmentChange}
        placeholder='Assessment (by role)'
      />
      <DatePicker
        onChange={onFromDateChange}
        value={fromDate}
        label='From'
      />
      <DatePicker
        onChange={onToDateChange}
        value={toDate}
        label='To'
      />
      <button 
        className="button button-click-effect"
        onClick={onFilterClick}
      >
        Filter
      </button>
    </div>
  );
};