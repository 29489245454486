import { useEffect, useState } from 'react';
import { RiArrowLeftLine } from 'react-icons/ri';
import ReactPaginate from 'react-paginate';
import { PagesPerRow } from '.';
import './Pagination.sass';

export interface PaginationProps<Item> {
  paginateItems: Item[],
  onPageChange: ( items: Item[] ) => void
}

export const Pagination = <Item,>(
  { paginateItems, onPageChange } : PaginationProps<Item>
) => {

  const [ itemOffset, setItemOffset ] = useState( 0 );
  const [ pageCount, setPageCount ] = useState( 0 );
  const [ itemsPerPage, setItemsPerPage ] = useState( 5 );

  const onChange = ( event: {selected: number} ) => {
    const newOffset = ( event.selected * itemsPerPage ) % paginateItems.length;
    setItemOffset( newOffset );
  };

  useEffect( () => {
    const endOffset = itemOffset + itemsPerPage;
    onPageChange( paginateItems.slice( itemOffset,endOffset ) );
    setPageCount( Math.ceil( paginateItems.length / itemsPerPage ) );
  }, [ itemOffset, itemsPerPage, paginateItems ] );

  return (
    <div className="pagination-container">
      <ReactPaginate 
        pageCount={pageCount}
        onPageChange={onChange}
        pageRangeDisplayed={5}
        className='pagination'
        previousLabel={<RiArrowLeftLine />}
        nextLabel={<RiArrowLeftLine />}
        breakLabel='...'
      />
      <PagesPerRow onChange={setItemsPerPage} />
    </div>
  );
};