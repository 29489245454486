import * as yup from 'yup';
import { CompanyAccount } from '../services/models';

export const createUserSchema = yup.object( {
  name: yup.string().required(),
  team: yup.string(),
  email: yup.string().email().required(),
  password: yup.string().required(),
  description: yup.string(),
  role: yup.string().oneOf( [ 'admin', 'manager' ,'contributor' ] ).required(),
  company: yup.string().required()
} );
export type CreateUserFields = yup.InferType<typeof createUserSchema> & {role: 'admin' | 'manager' | 'contributor'};

export const createOrganizationSchema = yup.object( {
  name: yup.string().required(),
  companySiteUrl: yup.string().required(),
  cultureNeeds: yup.string().oneOf( [ 'protected', 'fixed' ] ).required()
} );
export type CreateOrganizationFields = (
  yup.InferType<typeof createOrganizationSchema> & Pick<CompanyAccount, 'cultureNeeds'>
)