import { FitTechSubmission } from '../../../services/models';
import { Modal } from '../../modal/modalBase/Modal';
import { SubmissionAnswers } from './SubmissionAnswers';
import { SubmissionDetails } from './SubmissionDetails';
import { SubmissionScores } from './SubmissionResults';
import { SubmissionSecondaryAnswers } from './SubmissionSecondaryAnswers';

export interface FitTechSubmissionModalProps {
  submission?: FitTechSubmission,
  onClose: () => void,
  isOpen: boolean
}

export const FitTechSubmissionModal = ( { isOpen, submission, onClose } : FitTechSubmissionModalProps ) => {
  
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={submission ? `${submission.firstName} ${submission.lastName}` : ''}
    >
      {submission ? (
        <div className="fit-tech-submission-modal">
          <section>
            <h1>Details</h1>
            <SubmissionDetails submission={submission} />
          </section>
          <section>
            <h1>Results</h1>
            <SubmissionScores submission={submission} />
          </section>
          <section>
            <h1>Answers</h1>
            <SubmissionAnswers submission={submission} />
          </section>
          <section>
            <h1>Secondary Questions Answers</h1>
            <SubmissionSecondaryAnswers submission={submission} />
          </section>
        </div>
      ) : null}
    </Modal>
  );
};