import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { mainApi } from '../services/mainApi';
import { appSlice } from './slices/appSlice';
import { authSlice } from './slices/authSlice';

export const store = configureStore( {
  reducer: {
    [authSlice.name]: authSlice.reducer,
    [appSlice.name]: appSlice.reducer,
    [mainApi.reducerPath]: mainApi.reducer
  },
  middleware: ( getDefaultMiddleware ) => (
    getDefaultMiddleware( {
      serializableCheck: false
    } )
      .concat( mainApi.middleware )
  )

} );

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useTypedSelector : TypedUseSelectorHook<RootState> = useSelector;
export type Store = typeof store;