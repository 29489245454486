import { ThemeProvider } from '@mui/material';
import { useEffect } from 'react';
import { RootRouter } from '../navigation/RootRouter';
import { authService } from '../services/authService';
import { servicesInjectStore } from '../services/baseInstances';
import { store, useTypedSelector } from '../state/store';
import { muiTheme } from '../utils';
import { AppToaster } from './AppToaster';
import { LightTheme } from './ui/LightTheme';


servicesInjectStore( store );

function App() {

  const theme = useTypedSelector( state => state.app.theme );

  useEffect( () => {
    authService.validate();
  } );

  return (
    <ThemeProvider theme={muiTheme( theme === 'light' )}>
      <div className="App">
        {theme === 'light' && <LightTheme />}
        <RootRouter />
        <AppToaster />
      </div>
    </ThemeProvider>
  );
}

export default App;
