import { useMemo } from 'react';
import { useChangeFitTechStatusMutation } from '../../../services/mainApi';
import { CulturalAssessment } from '../../../services/models';
import { ChangeFitTechStatusBody } from '../../../validation/requestsBodyValidation';
import { Select } from '../../ui/Controls/Select';

export interface FitTechStatusSelectProps {
  assessment: CulturalAssessment
}

export const FitTechStatusSelect = ( { assessment } : FitTechStatusSelectProps ) => {
  
  console.log( 'assessment', assessment );

  const [ changeStatus ] = useChangeFitTechStatusMutation();

  const currentStatus : ChangeFitTechStatusBody['status'] = useMemo( () => (
    assessment.fitTechStatus.paid
        ? 'active'
        : assessment.fitTechStatus.trial === 'active'
          ? 'trial'
          : assessment.fitTechStatus.trial === 'notActivated' ? 'notStarted' : 'inactive'
  ), [ assessment ] );

  const onChange = ( status: string ) => {
    if ( status !== currentStatus ) {
      changeStatus( {
        assessmentId: assessment._id,
        companyId: assessment.companyId,
        status: status as ChangeFitTechStatusBody['status']
      } );
    }
  };

  return (
    <Select
      defaultValue={currentStatus}
      options={new Map( [
        [ 'active', 'Active' ],
        [ 'inactive', 'Inactive' ],
        [ 'notStarted', 'Not Started' ],
        [ 'trial', 'Trial' ]
      ] as [ChangeFitTechStatusBody['status'], string][] )}
      onChange={onChange}
    />
  );
};