import { useState } from 'react';
import { useCreateCompanyMutation, useDeleteCompanyMutation, useGetCompaniesQuery } from '../../services/mainApi';
import { CompanyAccount, CompanyAccountTeam } from '../../services/models';
import { Modal } from '../modal/modalBase/Modal';
import { TableListQuery } from '../ui/TableList/TableListQuery';
import '../../assets/css/screens/CompaniesScreen.css';
import { Formik } from 'formik';
import { createOrganizationSchema, CreateOrganizationFields } from '../../validation/formsValidation';
import { LabeledTextInput } from '../forms/LabeledTextInput';
import { SelectField } from '../forms/SelectField';

export const CompaniesScreen = () => {

  const [ createCompany ] = useCreateCompanyMutation();
  const [ deleteCompany ] = useDeleteCompanyMutation();
  
  const [ teams, setTeams ] = useState<CompanyAccountTeam[]>( );
  const [ isCreateModalOpen, setCreateModalOpen ] = useState( false );

  const [ companyToDelete, setCompanyToDelete ] = useState<CompanyAccount>();

  const onDeleteCompany = ( company: CompanyAccount ) => () => {
    deleteCompany( company._id ).unwrap().then( () => {
      setCompanyToDelete( undefined );
    } );
  };
  
  return (
    <>
      <TableListQuery<CompanyAccount, 'actions'>
        queryHook={useGetCompaniesQuery}
        fieldsToDisplay={[ 'name', 'users', 'teams', 'actions' ]}
        headings={{
          name: 'Name',
          users: 'Users',
          teams: 'Teams'
        }}
        fieldTransformations={{
          users: users => users?.length.toString() || '0',
          teams: teams => (
            <span className='teams' onClick={() => setTeams( teams )}>
              {teams.length.toString()} team(s)
            </span>
          )
        }}
        buttons={[ {
          text: 'Create Company',
          onClick: () => setCreateModalOpen( true )
        } ]}
        customFields={{
          actions: company => (
            <button className="button small" onClick={() => setCompanyToDelete( company )}>Delete</button>
          )
        }}
      />
      <Modal
        isOpen={!!teams}
        onClose={() => setTeams( undefined )}
        title='Teams'
      >
        {teams && teams.map( team => (
          <div className="team-item">
            <span>{team.name}</span>
            <span>{team.description}</span>
          </div>
        ) )}
      </Modal>
      <Modal
        isOpen={isCreateModalOpen}
        onClose={() => setCreateModalOpen( false )}
        title='Create Company'
      >
        <Formik
          initialValues={{
            name: '',
            cultureNeeds: 'protected',
            companySiteUrl: ''
          } as CreateOrganizationFields}
          onSubmit={values => {
            createCompany( values ).unwrap().then( () => setCreateModalOpen( false ) );
          }}
          validationSchema={createOrganizationSchema}
          validateOnMount={true}
        >
          {( { handleSubmit, isValid } ) => (
            <form 
              onSubmit={handleSubmit}
              style={{
                display: 'flex',
                width: 400,
                flexWrap: 'wrap'
              }}
              className='form-create-company'
            >
              <LabeledTextInput
                label='Company Name'
                name='name'
              />
              <LabeledTextInput
                label='Company Web Site Url'
                name='companySiteUrl'
                type='url'
              />
              <SelectField
                name='cultureNeeds'
                label='Company Culture Needs'
                options={new Map( [
                  [ 'protected', 'to be protected' ],
                  [ 'fixed', 'to be fixed' ],
                ] )}
              />
              <button 
                type='submit' 
                className='button' 
                style={{ width: '100%', marginTop: 32 }}
                disabled={!isValid}
              >
                Create
              </button>
            </form>
          )}
        </Formik>
      </Modal>
      <Modal
        isOpen={!!companyToDelete}
        onClose={() => setCompanyToDelete( undefined )}
        title='Delete company'
        style={{ body: { maxWidth: 500, fontSize: '.7em', textAlign: 'center' } }}
      >
        {companyToDelete && (
          <>
            <h2>Are you sure you want to delete "{companyToDelete.name}" company?</h2>
            <button className="button" onClick={onDeleteCompany( companyToDelete )}>Delete</button>
          </>
        )}
      </Modal>
    </>
  );
};