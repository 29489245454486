import { useMemo } from 'react';
import { useGetAssessmentAttributesQuery } from '../../../services/mainApi';


export interface AttributeItemProps {
  attributeId: string
}

export const AttributeItem = ( { attributeId } : AttributeItemProps ) => {

  const attributes = useGetAssessmentAttributesQuery();

  const attribute = useMemo( () => {
    if ( !attributes.data ) return null;
    return attributes.data.find( attr => attr._id === attributeId );
  }, [ attributes.data, attributeId ] );

  if ( !attribute ) return null;
  
  return (
    <li className="attribute-item box">
      <img src={attribute.iconUrl} alt={attribute.title} />
      <span>{attribute.title}</span>
    </li>
  );
};