
import { ChangeEventHandler, useMemo } from 'react';
import { RiCheckFill } from 'react-icons/ri';
import './CheckBox.sass';

export interface CheckBoxProps {
  checked: boolean,
  onChange: ( checked: boolean ) => void,
  name?: string,
  label: string | JSX.Element,
  radioView?: boolean
}

export const CheckBox = ( { checked, onChange, name, label, radioView } : CheckBoxProps ) => {

  const inputName = useMemo( () => (
    name || randomString( 10 )
  ), [ name ] );

  const onInputChange : ChangeEventHandler<HTMLInputElement> = event => {
    onChange( event.target.checked );
  };

  return (
    <div className={[ 'checkbox', radioView ? 'radio' : undefined ].join( ' ' )}>
      <label htmlFor={inputName}>
        <input 
          type="checkbox" 
          id={inputName}
          name={inputName}
          checked={checked}
          onChange={onInputChange}
          hidden
        />
        <div className={'checkbox-view'}>
          <RiCheckFill />
        </div>
        {label}
      </label>
    </div>
  );
};

function randomString( length: number ) {
  let result           = '';
  const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for ( let i = 0; i < length; i++ ) {
    result += characters.charAt( Math.floor( Math.random() * charactersLength ) );
  }
  return result;
}