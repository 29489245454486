import { DateTime } from 'luxon';
import { FitTechSubmission } from '../../../services/models';


export interface SubmissionDetailsProps {
  submission: FitTechSubmission
}

export const SubmissionDetails = ( { submission } : SubmissionDetailsProps ) => (
  <ul className="details">
    <li>
      <span>First Name</span>
      <span>{submission.firstName}</span>
    </li>
    <li>
      <span>Last Name</span>
      <span>{submission.lastName}</span>
    </li>
    <li>
      <span>Age</span>
      <span>{submission.age || '-'}</span>
    </li>
    <li>
      <span>Email</span>
      <span>{submission.email}</span>
    </li>
    <li>
      <span>Ethnicity</span>
      <span>{submission.ethnicity ? ethnicityLabels.find( e => e.value === submission.ethnicity )?.label : '-'}</span>
    </li>
    <li>
      <span>Submitted At</span>
      <span>{DateTime.fromJSDate( submission.createdAt ).toFormat( 'f' )}</span>
    </li>
  </ul>
);



const ethnicityLabels = [
  { label: 'America Indian or Alaska Native', value: 'americaNative' },
  { label: 'Asian', value: 'asian' },
  { label: 'Black or African American', value: 'african' },
  { label: 'Hispanic or Latino', value: 'latino' },
  { label: 'Native Hawaiian or Other Pacific Islander', value: 'islander' },
  { label: 'White', value: 'white' },
  { label: 'Other', value: 'other' },
];