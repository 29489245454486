import styled from '@emotion/styled';
import { LocalizationProvider, MobileDatePicker } from '@mui/lab';
import DateAdapter from '@mui/lab/AdapterLuxon';
import { TextField } from '@mui/material';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useTypedSelector } from '../../../../state/store';
import { themeColors } from '../../../../utils';

export interface DatePickerProps {
  value: DateTime | null,
  onChange: ( date: DateTime | null ) => void,
  label?: string
}

const StyledTextField = ( lightTheme: boolean ) => {

  const colors = themeColors( lightTheme );
  
  return styled( TextField )( {
    '& input': {
      padding: 8,
      background: colors.neutral0

    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: colors.neutral30,
      }
    },
    '& label': {
      top: -8
    }
  } );
};

export const DatePicker = ( { value, onChange, label } : DatePickerProps ) => {

  const { theme } = useTypedSelector( state => state.app );

  const ThemeStyledTextField = useMemo( () => {
    return StyledTextField( theme === 'light' );
  }, [ theme ] );

  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <MobileDatePicker
        onChange={onChange}
        value={value}
        label={label}
        renderInput={params => <ThemeStyledTextField {...params} />}
      />
    </LocalizationProvider>
  );
};