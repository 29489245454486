import { useMemo } from 'react';
import { useGetFitTechSecondaryQuestionsQuery } from '../services/mainApi';
import { FitTechSecondaryQuestion } from '../services/models';


export const useFitTechSecondaryQuestions = () => {

  const questions = useGetFitTechSecondaryQuestionsQuery();

  const questionsById = useMemo( () => {
    if ( !questions.data ) return;
    const byIds : Record<string, FitTechSecondaryQuestion> = {};
    questions.data.forEach( q => byIds[q._id] = q );
    return byIds;
  }, [ questions.data ] );
  
  return questionsById;
};