import { DateTime } from 'luxon';
import { useState } from 'react';
import { useGetAssessmentsQuery, useGetCompaniesQuery } from '../../../services/mainApi';
import { CulturalAssessment } from '../../../services/models';
import { TableListQuery } from '../../ui/TableList/TableListQuery';
import { AssessmentsFilters, AssessmentsFiltersValues } from './AssessmentsFilters';
import './AssessmentsScreen.sass';
import { FitTechStatusSelect } from './FitTechStatusSelect';

export const AssessmentsScreen = () => {

  const [ filters, setFilters ] = useState<AssessmentsFiltersValues>();
  const companies = useGetCompaniesQuery();

  return (
    <>
      <AssessmentsFilters onFilter={setFilters} />
      {filters?.company ? (
        <TableListQuery<CulturalAssessment, 'fitTechStatusField'>
          queryHook={useGetAssessmentsQuery.bind( null, { 
            companyId: filters.company === 'all' ? undefined : filters.company 
          } ) as never}
          fieldsToDisplay={[ 'companyId', 'submissions', 'fitTechStatusField', 'role', 'fitTechSubmissions', 'createdAt' ]}
          headings={{
            companyId: 'Company',
            submissions: 'Submissions',
            fitTechStatusField: 'FitTech status',
            role: 'FitTech role',
            fitTechSubmissions: 'FitTech submissions',
            createdAt: 'Created at'
          }}
          fieldTransformations={{
            createdAt: date => date ? DateTime.fromJSDate( date ).toFormat( 'f' ) : '-',
            companyId: id => companies.data?.find( company => company._id === id )?.name || '-',
            submissions: submissions => submissions.length.toString(),
            fitTechSubmissions: submissions => submissions.length.toString(),
            role: role => <span className='role'>{role}</span>
          }}
          customFields={{
            fitTechStatusField: assessment => <FitTechStatusSelect assessment={assessment} />
          }}
          className={'small-list'}
        />
      ) : (
        <div className="box" style={{ marginTop: 40 }}>
          Please specify the filters first
        </div>
      )}      
    </>
  );
};