import { RiBook3Line, RiBookLine, RiDatabaseLine, RiPieChart2Line, RiTeamLine, RiUser2Line } from 'react-icons/ri';
import { Route, Routes } from 'react-router-dom';
import '../assets/css/wrappers/Container.css';
import { NavigationPanel } from '../components/navigation/NavigationPanel';
import { AssessmentsScreen } from '../components/screens/AssessmentsScreen';
import { CompaniesScreen } from '../components/screens/CompaniesScreen';
import { DummyDataScreen } from '../components/screens/DummyDataScreen';
import { FitTechSubmissionsScreen } from '../components/screens/FitTechSubmissionsScreen';
import { SubmissionsScreen } from '../components/screens/SubmissionsScreen';
import { UsersScreen } from '../components/screens/UsersScreen';

export const AppRouter = () => (
  <>
    <NavigationPanel 
      navigationItems={[
        { to: '/companies', label: 'Companies', icon: RiUser2Line },
        { to: '/users', label: 'Users', icon: RiTeamLine },
        { to: '/dummy', label: 'Dummy Data', icon: RiDatabaseLine },
        { to: '/assessments', label: 'Assessment Models', icon: RiPieChart2Line },
        { to: '/submissions', label: 'Submissions', icon: RiBookLine },
        { to: '/fitTechSubmissions', label: 'FitTech Submissions', icon: RiBook3Line },
      ]}
    />
    <div className="container">
      <Routes>
        <Route path='/companies' element={<CompaniesScreen />} />
        <Route path='/users' element={<UsersScreen />} />
        <Route path='/dummy' element={<DummyDataScreen />} />
        <Route path='/submissions' element={<SubmissionsScreen />} />
        <Route path='/fitTechSubmissions' element={<FitTechSubmissionsScreen />} />
        <Route path='/assessments' element={<AssessmentsScreen />} />
      </Routes>
    </div>
  </>
);