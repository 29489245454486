import { RiArrowRightLine, RiLogoutBoxLine } from 'react-icons/ri';
import '../../assets/css/components/navigation/NavigationPanel.css';
import { authService } from '../../services/authService';
import { ThemeToggle } from '../ui/Controls/ThemeToggle';
import { NavigationItem, NavigationItemProps } from './NavigationItem';

export interface NavigationPanelProps {
  navigationItems: NavigationItemProps[]
}

export const NavigationPanel = ( { navigationItems } : NavigationPanelProps ) => {

  const onLogoutClick = () => {
    authService.logout();
  };
  
  return (
    <div className="navigation">
      <ThemeToggle />
      <nav>
        {navigationItems.map( ( item, index ) => 
          <NavigationItem key={index} to={item.to} label={item.label} icon={item.icon} /> 
        )}
        <div onClick={onLogoutClick} className="navigation-item button-click-effect">
          <RiLogoutBoxLine className='icon' />
          <span>Logout</span>
          <RiArrowRightLine className='arrow'/>
        </div>
      </nav>
    </div>
  );
};