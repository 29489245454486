import { createTheme } from '@mui/material';
import { themeColors } from './colors';



export const muiTheme = ( lightTheme: boolean ) => {

  const colors = themeColors( lightTheme );

  return createTheme( {
    palette: {
      primary: {
        main: colors.primary,
        '300': colors.primary75,
        '200': colors.primary50,
        '100': colors.primary25
      },
      secondary: {
        main: colors.secondary
      },
      background: {
        default: colors.neutral0,
        paper: colors.neutral0
      },
      grey: {
        '50': colors.neutral0,
        '100': colors.neutral5,
        '200': colors.neutral10,
        '300': colors.neutral20,
        '400': colors.neutral30,
        '500': colors.neutral40,
        '600': colors.neutral50,
        '700': colors.neutral60,
        '800': colors.neutral70,
        '900': colors.neutral80
      },
      text: {
        primary: colors.neutral90,
        disabled: colors.neutral70,
        secondary: colors.neutral80
      },
      action: {
        active: colors.neutral90,
      },
      common: {
        black: colors.neutral90,
        white: colors.neutral0
      },
      contrastThreshold: .9,
      info: {
        main: colors.primary,
        '300': colors.primary75,
        '200': colors.primary50,
        '100': colors.primary25
      },
      error: {
        main: colors.danger,
        '300': colors.dangerLight
      },
    },
  } );
};