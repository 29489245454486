import { Form, Formik } from 'formik';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useGenerateDummyDataMutation } from '../../../services/mainApi';
import { DummyDataBody, dummyDataSchema } from '../../../validation/dummyDataValidation';
import { LabeledTextInput } from '../../forms/LabeledTextInput';
import { Submit } from '../../forms/Submit';
import { AssessmentField } from './AssessmentField';
import './DummyDataScreen.sass';

export const DummyDataScreen = () => {

  const [ generateData, { isSuccess, isError, data, reset } ] = useGenerateDummyDataMutation();
  
  const navigate = useNavigate();

  const initialValues: DummyDataBody = {
    assessments: [
      {
        submissionsAmount: 5,
        targetSubmissionsAmount: 7,
        fitTech: {
          unlocked: true,
          submissionsAmount: 3
        }
      }
    ],
    password: randomString( 16 ),
    usersAmount: 15
  };

  const onSubmit = ( values: typeof initialValues ) => {
    generateData( values );
  };

  useEffect( () => {
    if ( isError ) {
      toast.success( 'Something went wrong' );
      navigate( '/companies' );
    }
  }, [ isError ] );

  if ( isSuccess && data ) {
    return (
      <section className="dummy-data-success">
        <h1>Dummy data has been successfully generated!</h1>
        <p>
          The name of generated company is <span className='s'>{data.companyName}</span>.
        </p>
        <p>
          You can login into company admin account using <span className='m'>{data.adminEmail}</span> email and the previously specified password.
        </p>
        <button className="button" onClick={reset}>Generate more</button>
      </section>
    );
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={dummyDataSchema}
    >
      <Form className='dummy-data-form'>
        <div className="fields">
          <LabeledTextInput
            label='Password that will be used for every generated user. Copy it so you can login later:'
            name='password'
          />
          <LabeledTextInput
            label='Amount of users to generate'
            name='usersAmount'
            type='number'
          />
        </div>
        <section className="assessments">
          <h1>Culture Surveys</h1>
          <AssessmentField />
        </section>

        <Submit text='Generate Data' />
      </Form>
    </Formik>
  );
};

function randomString( length: number ) {
  let result           = '';
  const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for ( let i = 0; i < length; i++ ) {
    result += characters.charAt( Math.floor( Math.random() * charactersLength ) );
  }
  return result;
}