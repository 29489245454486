import { CSSProperties } from 'react';
import { useGenerateFitTechDocumentMutation } from '../../../services/mainApi';

export interface GenerateFitTechDocButtonProps {
  submissionId: string,
  style?: CSSProperties
}

export const GenerateFitTechDocButton = ( { submissionId, style } : GenerateFitTechDocButtonProps ) => {

  const [ generateDocument, { isLoading } ] = useGenerateFitTechDocumentMutation();


  const onClick = () => {
    generateDocument( submissionId );
  };

  return (
    <button 
      className='button button-click-effect small no-margin' 
      disabled={isLoading} 
      onClick={onClick}
      style={style}
    >
      Generate
    </button>
  );
};