import { Formik } from 'formik';
import { DateTime } from 'luxon';
import { useState } from 'react';
import { useCreateUserMutation, useDeleteUserMutation, useGetCompaniesQuery, useGetUsersQuery } from '../../services/mainApi';
import { CompanyAccount, UserAccount } from '../../services/models';
import { CreateUserFields, createUserSchema } from '../../validation/formsValidation';
import { LabeledTextInput } from '../forms/LabeledTextInput';
import { SelectField } from '../forms/SelectField';
import { Modal } from '../modal/modalBase/Modal';
import { TableListQuery } from '../ui/TableList/TableListQuery';
import { AiOutlineLogin } from 'react-icons/ai';

export const UsersScreen = () => {

  const companies = useGetCompaniesQuery();
  const [ createUser ] = useCreateUserMutation();
  const [ deleteUser ] = useDeleteUserMutation();
  const { data } = useGetUsersQuery();
  
  const [ isCreateModalOpen, setCreateModalOpen ] = useState( false );
  const [ isDeleteModalOpen, setDeleteModalOpen ] = useState( false );

  const [ userToDelete, setUserToDelete ] = useState<UserAccount>();  
    
  const handleLoginClick = ( user: any ) => {
    if ( data ) {
     const userToLogin = data.some( ( userss )=> userss?._id === user );
     if ( userToLogin ) {
      const userss =data.find( ( users )=> users._id == user )?.verifiedat;
      if ( userss != null && userss ) {
        const baseUrl = 'https://hire.staffgeek.com/';
        const loginUrl = `${baseUrl}?userId=${user}`;
        window.location.href = loginUrl;
      }
     } 
    }
  };

  const renderTooltip = ( verified: any ) => {
    return verified ? 'LogIn' : 'Not Verified';
  };
  
  return (
    <>
      <TableListQuery<UserAccount>
        queryHook={useGetUsersQuery}
        fieldsToDisplay={[ 'name', 'email', 'role', 'companyId', 'createdAt', 'lastLogin', '_id' ]}
        headings={{
          name: 'Name',
          email: 'Email',
          role: 'Role',
          companyId: 'Organization',
          createdAt: 'Registered',
          lastLogin: 'Last Login',
          _id: 'Action',
        }}
        fieldTransformations={{
          companyId: company => company.name,
          createdAt: date => DateTime.fromJSDate( date ).toFormat( 'f' ),
          lastLogin: date => date.getMilliseconds() > 0 ? DateTime.fromJSDate( date ).toFormat( 'f' ) : 'never',
          _id: _id => (
              <AiOutlineLogin
                size={23}
                onClick={() => handleLoginClick( _id )}
                style={{ cursor: 'pointer' }}
                title={renderTooltip( data?.find( ( id ) => id._id == _id  )?.verifiedat )}
              />
          )
        }}
        filters={{
          company: {
            defaultValue: 'all',
            options: new Map(
              companies.data
                ? companies.data.map( company => [ company._id, company.name ] )
                : []
            ).set( 'all', 'All companies' ),
            filterFn: ( companyId, users ) => {
              if ( companyId === 'all' ) return users;
              console.log( { companies: users, companyId } );
              return users.filter( user => user.companyId._id === companyId );
            },
            label: 'Company'
          }
        }}
        buttons={[ {
          text: 'Create User',
          onClick: () => setCreateModalOpen( true )
        } ]}
        onEdit={user => {
          setUserToDelete( user );
          setDeleteModalOpen( true );
        }}
        editable
      />
      <Modal
        isOpen={isCreateModalOpen}
        onClose={() => setCreateModalOpen( false )}
        title='Create User'
      >
        <Formik
          initialValues={{
            name: '',
            description: '',
            email: '',
            password: '',
            role: 'manager',
            team: '',
            company: ''
          } as CreateUserFields}
          onSubmit={values => {
            createUser( values ).unwrap().then( () => setCreateModalOpen( false ) );
          }}
          validationSchema={createUserSchema}
          validateOnMount={true}
        >
          {( { values, handleSubmit, isValid } ) => (
            <form 
              onSubmit={handleSubmit}
              style={{
                display: 'flex',
                width: 800,
                flexWrap: 'wrap'
              }}
            >

              <div style={{ width: '50%' }}>
                <LabeledTextInput
                  label='Name'
                  name='name'
                />
                <LabeledTextInput
                  label='Description'
                  name='description'
                />
                <LabeledTextInput
                  label='Email'
                  name='email'
                  type='email'
                />
                <LabeledTextInput
                  label='Password'
                  name='password'
                />
              </div>
              <div style={{ width: 'calc( 50% - 32px )', marginLeft: 32 }}>
                <SelectField
                  name='company'
                  options={new Map(
                    companies.data 
                      ? companies.data.map( company => [ company._id, company.name ] )
                      : []
                  )}
                  label='Company'
                />
                {values.company && (
                  <>
                    <SelectField
                      name='team'
                      label='Team'
                      options={new Map(
                        ( ( companies.data as CompanyAccount[] )
                          .find( c => c._id === values.company ) as CompanyAccount )
                          .teams.map( team => [ team._id, team.name ] )
                      )}
                    />
                    <SelectField
                      name='role'
                      label='Role'
                      options={new Map( [
                        [ 'admin', 'Admin' ],
                        [ 'manager', 'Manager' ],
                        [ 'contributor', 'Contributor' ],
                      ] )}
                    />
                  </>
                )}
              </div>
              <button 
                type='submit' 
                className='button' 
                style={{ width: 150, marginLeft: 'auto', marginRight: 'auto', marginTop: 32 }}
                disabled={!isValid}
              >
                Create
              </button>
            </form>
          )}
        </Formik>
      </Modal>
      <Modal 
        isOpen={isDeleteModalOpen}
        onClose={() => setDeleteModalOpen( false )}
        title='Delete user'
      >
        {userToDelete && (
          <button
            className='button'
            onClick={() => {
              deleteUser( userToDelete._id ).unwrap().then( () => {
                setDeleteModalOpen( false );
                setUserToDelete( undefined );
              } );

            }}
          >
            Delete user {userToDelete.name}
          </button>
        )}
      </Modal>
    </>
  );
};