import { RiErrorWarningLine } from 'react-icons/ri';
import '../../../assets/css/components/boxes/ErrorBox.css';

export interface ErrorBoxProps {
  error: string,
  possibleAction?: {
    text: string,
    buttonText: string,
    onClick: () => void
  }
}

export const ErrorBox = ( { error, possibleAction } : ErrorBoxProps ) => (
  <div className="box error-box">
    <RiErrorWarningLine className='main-icon' />
    <p>{typeof error === 'string' ? error : JSON.stringify( error )}</p>
    {possibleAction && (
      <div className="possible-action">
        <p>{possibleAction.text}</p>
        <button className="button button-click-effect" onClick={possibleAction.onClick}>
          {possibleAction.buttonText}
        </button>
      </div>
    )}
  </div>
);