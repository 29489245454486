
import { useEffect, useMemo } from 'react';
import ReactSelect from 'react-select';
import { useTypedSelector } from '../../../../state/store';
import { themeColors } from '../../../../utils';
import './Select.sass';

export interface SelectProps<IsMulti extends boolean> {
  options: Map<string, string>,
  onChange: ( value: IsMulti extends false ? string : string[] ) => void
  placeholder?: string,
  defaultValue?: IsMulti extends false ? string : string[],
  label?: string,
  big?: true,
  value?: null,
  isMulti?: IsMulti
}

export const Select = <IsMulti extends boolean = false>(
  { options, placeholder, onChange, defaultValue, label, big, value, isMulti } : SelectProps<IsMulti>
) => {

  const { theme } = useTypedSelector( state => state.app );
  
  const parsedOptions = useMemo( () => {
    const resultOptions : {label: string, value: string}[] = [];

    options.forEach( ( label, value ) => resultOptions.push( { value, label } ) );
    
    return resultOptions;
  }, [ options ] );

  useEffect( () => {
    defaultValue && onChange( defaultValue );
  }, [ defaultValue ] );

  const onSelectChange = ( value: {value: string} | {value: string}[] ) => {
    onChange( (
      Array.isArray( value )
        ? value.map( val => val.value )
        : value.value
    ) as never );
  };

  return (
    <div className="select-input">
      {label && <label>{label}</label>}
      <ReactSelect
        value={value}
        className='select'
        options={parsedOptions}
        theme={( base ) => ( {
          ...base,
          colors: themeColors( theme === 'light' ),
        } )}
        components={{
          IndicatorSeparator: null
        }}
        styles={{
          control: base => ( { 
            ...base, 
            height: big ? '48px' : base.height,
            width: 'auto'
          } ),
        }}
        placeholder={placeholder}
        onChange={onSelectChange as never}
        defaultValue={defaultValue && parsedOptions.find( opt => opt.value === defaultValue ) || undefined}
        isMulti={isMulti}
      />
    </div>
  );
  
};