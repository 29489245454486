import * as yup from 'yup';

export const dummyDataSchema = yup.object( {
  usersAmount: yup.number().min( 1 ).max( 50 ).required(),
  assessments: yup.array( yup.object( {
    submissionsAmount: yup.number().min( 1 ).max( 50 ).required(),
    targetSubmissionsAmount: yup.number().required(),
    fitTech: yup.object( {
      unlocked: yup.boolean().required(),
      submissionsAmount: yup.number().required()
    } )
  } ) ).required(),
  password: yup.string().required()
} );
export type DummyDataBody = yup.InferType<typeof dummyDataSchema>;