import { skipToken } from '@reduxjs/toolkit/dist/query';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { RiCheckLine, RiCloseLine } from 'react-icons/ri';
import { useGetFitTechSubmissionsQuery } from '../../../services/mainApi';
import { FitTechSubmission } from '../../../services/models';
import { GenerateFitTechDocButton } from '../../ui/Controls/GenerateFitTechDocButton';
import { SubmissionsFilters, SubmissionsFiltersValues } from '../../ui/Controls/SubmissionsFilters';
import { TableListQuery } from '../../ui/TableList/TableListQuery';
import { FitTechSubmissionModal } from './FitTechSubmissionModal';
import './FitTechSubmissionsScreen.sass';

export const FitTechSubmissionsScreen = () => {

  const [ filters, setFilters ] = useState<SubmissionsFiltersValues>();
  const [ submissionToView, setSubmissionToView ] = useState<FitTechSubmission>();
  const [ isSubmissionModalOpen, setSubmissionModalOpen ] = useState( false );

  const queryArg = useMemo( () => (
    filters ? {
      assessmentIds: filters.assessment && filters.assessment !== 'all' ? [ filters.assessment ] : undefined,
      companyIds: filters.company && filters.company !== 'all' ? [ filters.company ] : undefined,
      end: filters.toDate ? filters.toDate.toJSDate() : undefined,
      start: filters.fromDate ? filters.fromDate.toJSDate() : undefined,
    } : skipToken
  ), [ filters ] );
  
  const submissions = useGetFitTechSubmissionsQuery( queryArg );
  
  const queryHook = useMemo( () => (
    useGetFitTechSubmissionsQuery.bind( null, queryArg )
  ), [ filters ] );

  useEffect( () => {
    if ( submissionToView && submissions.data ) 
      setSubmissionToView( submissions.data.find( s => s._id === submissionToView._id ) );
  }, [ submissions.data ] );

  
  const toggleSubmissionModal = ( open: boolean ) => () => setSubmissionModalOpen( open );
  const openSubmission = ( submission: FitTechSubmission ) => () => {
    setSubmissionToView( submission );
    toggleSubmissionModal( true )();
  };

  return (
    <>
      <SubmissionsFilters
        onFilter={setFilters}
      />
      {filters ? (
        <TableListQuery<FitTechSubmission, 'modal' | 'generate'>
          queryHook={queryHook as never}
          fieldsToDisplay={[ 'firstName', 'lastName', 'email', 'age', 'createdAt', 'generate', 'modal' ]}
          headings={{
            firstName: 'First Name',
            lastName: 'Last Name',
            email: 'Email',
            age: 'Age',
            createdAt: 'Submitted At',
            generate: 'PDF'
          }}
          fieldTransformations={{
            createdAt: date => DateTime.fromJSDate( date ).toFormat( 'f' )
          }}
          customFields={{
            modal: submission => (
              <button className='button small no-margin' onClick={openSubmission( submission )} style={{ marginRight: 12 }}>
                View details
              </button>
            ),
            generate: submission => (
              <div className='generate-col'>
                {submission.pdfName ? <RiCheckLine data-generated={true} /> : <RiCloseLine data-generated={false} />}
                <GenerateFitTechDocButton submissionId={submission._id} style={{ marginRight: 16 }} />
              </div>
            )
          }}
        />
      ) : (
        <div className="box" style={{ marginTop: 40 }}>
          Please specify the filters first
        </div>
      )}
      <FitTechSubmissionModal
        isOpen={isSubmissionModalOpen}
        submission={submissionToView}
        onClose={toggleSubmissionModal( false )}
      />
    </>
  );
};