import { skipToken } from '@reduxjs/toolkit/dist/query';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { useGetSubmissionsQuery } from '../../../services/mainApi';
import { CulturalAssessmentSubmission } from '../../../services/models';
import { SubmissionsFilters, SubmissionsFiltersValues } from '../../ui/Controls/SubmissionsFilters';
import { TableListQuery } from '../../ui/TableList/TableListQuery';
import { SubmissionModal } from './SubmissionModal';
import './SubmissionsScreen.sass';

export const SubmissionsScreen = () => {

  const [ filters, setFilters ] = useState<SubmissionsFiltersValues>();
  const [ submissionToView, setSubmissionToView ] = useState<CulturalAssessmentSubmission>();
  const [ isSubmissionModalOpen, setSubmissionModalOpen ] = useState( false );

  const queryHook = useMemo( () => (
    useGetSubmissionsQuery.bind( null, filters ? {
      assessmentIds: filters.assessment && filters.assessment !== 'all' ? [ filters.assessment ] : undefined,
      companyIds: filters.company && filters.company !== 'all' ? [ filters.company ] : undefined,
      end: filters.toDate ? filters.toDate.toJSDate() : undefined,
      start: filters.fromDate ? filters.fromDate.toJSDate() : undefined,
    } : skipToken )
  ), [ filters ] );

  const toggleSubmissionModal = ( open: boolean ) => () => setSubmissionModalOpen( open );
  const openSubmission = ( submission: CulturalAssessmentSubmission ) => () => {
    setSubmissionToView( submission );
    toggleSubmissionModal( true )();
  };

  return (
    <>
      <SubmissionsFilters 
        onFilter={setFilters}
      />
      {filters ? (
        <TableListQuery<CulturalAssessmentSubmission, 'modal'>
        queryHook={queryHook as never}
        fieldsToDisplay={[ 'name', 'email', 'group', 'createdAt', 'modal' ]}
        headings={{
          name: 'Name',
          email: 'Email',
          group: 'Group',
          createdAt: 'Submitted At'
        }}
        fieldTransformations={{
          createdAt: date => DateTime.fromJSDate( date ).toFormat( 'f' )
        }}
        customFields={{
          modal: submission => (
            <button className='button small no-margin' onClick={openSubmission( submission )}>
              View cards
            </button>
          )
        }}
      />
      ) : (
        <div className="box" style={{ marginTop: 40 }}>
          Please specify the filters first
        </div>
      )}
      <SubmissionModal
        onClose={toggleSubmissionModal( false )}
        submission={submissionToView}
        isOpen={isSubmissionModalOpen}
      />
    </>
  );
};