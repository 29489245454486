import { useMemo } from 'react';
import { RiFilePdfLine } from 'react-icons/ri';
import { useGetAssessmentAttributesQuery } from '../../../services/mainApi';
import { AssessmentAttribute, FitTechSubmission } from '../../../services/models';
import { GenerateFitTechDocButton } from '../../ui/Controls/GenerateFitTechDocButton';


export interface SubmissionScoresProps {
  submission: FitTechSubmission
}

export const SubmissionScores = ( { submission } : SubmissionScoresProps ) => {

  const attributes = useGetAssessmentAttributesQuery();

  const attributesById = useMemo( () => {
    if ( !attributes.data ) return;
    const byId : Record<string, AssessmentAttribute> = {};
    attributes.data.forEach( attribute => byId[attribute._id] = attribute );
    return byId;
  }, [ attributes.data ] );
  
  return (
    <ul className='scores'>
      <li className='wide'>
        <span>Overall</span>
        <span>{submission.overallScore}</span>
      </li>
      <li className='wide' style={{ position: 'relative' }}>
        <span>PDF</span>
        <span>
          {submission.pdfName ? (
            <a href={submission.pdfName} className='pdf' target={'_blank'}>
              Download <RiFilePdfLine />
            </a>
          ) : '-'}
        </span>
        <GenerateFitTechDocButton submissionId={submission._id} style={{ 
          position: 'absolute', left: 0, top: 'calc(100% + 4px)' 
        }} />
      </li>
      {submission.scores.map( score => (
        <li>
          <span>{attributesById && attributesById[score.attributeId]?.title}</span>
          <span>{score.score}</span>
        </li>
      ) )}
    </ul>
  );
};
