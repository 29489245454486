import axios from 'axios';
import { setAccessToken, setAuthError, setIsAuthorized, setRefreshToken, setValidated } from '../state/slices/authSlice';
import { store } from '../state/store';
import { authorizedAxiosInstance, baseUrl } from './baseInstances';

export type TokensPair = {
  accessToken: string,
  refreshToken: string
}

const client = axios.create( {
  baseURL: baseUrl
} );

const authClient = authorizedAxiosInstance;

async function login( login: string, password: string ) {
  try {
    const { data } = await client.post( '/auth/login', { login, password } );
    await processTokensPair( data );
  } catch ( error ) {
    processAuthError( error );
  }
}

async function refresh() {
  const refreshToken = store.getState().auth.refreshToken;
  if ( refreshToken ) {
    const { data, status } = await client.post( '/auth/refresh', { refreshToken } );
    if ( status === 200 ) processTokensPair( data );
    else logout();
  } else {
    logout();
  }
}

async function validate( ) {
  try {
    const { data } = await authClient.get( '/auth/valid' );
    if ( data ) store.dispatch( setIsAuthorized( true ) );
    else logout();
  } catch ( error ) {
    logout();
  } finally {
    store.dispatch( setValidated( true ) );
  }
}

function processAuthError ( error: unknown ) {
  let errorMessage = '';
  if ( axios.isAxiosError( error ) ) errorMessage = error.message;
  else errorMessage = 'Some error occurred while trying to log in';
  store.dispatch( setAuthError( errorMessage ) );
}

async function processTokensPair( tokensPair: TokensPair ) {
  store.dispatch( setAccessToken( tokensPair.accessToken ) );
  store.dispatch( setRefreshToken( tokensPair.refreshToken ) );
}

function logout () {
  store.dispatch( setIsAuthorized( false ) );
  localStorage.clear();
}

export const authService = {
  login,
  validate,
  refresh,
  logout
};