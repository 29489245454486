import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { DummyDataBody } from '../validation/dummyDataValidation';
import { CreateOrganizationFields, CreateUserFields } from '../validation/formsValidation';
import { ChangeFitTechStatusBody, GetAssessmentsBody, GetFitTechSubmissionsBody, GetSubmissionsBody } from '../validation/requestsBodyValidation';
import { axiosBaseQuery } from './baseInstances';
import { AssessmentAttribute, CompanyAccount, CulturalAssessment, CulturalAssessmentSubmission, DummyDataGenerationResponse, FitTechQuestion, FitTechSecondaryQuestion, FitTechSubmission, UserAccount } from './models';

export const mainApi = createApi( {
  baseQuery: axiosBaseQuery,
  reducerPath: 'mainApi',
  tagTypes: [ 
    'users', 'companies', 'assessments', 'attributes', 'fitTechSubmissions',
    'fitTechQuestions', 'fitTechSecondaryQuestions'
  ],
  endpoints: build => ( {
    getCompanies: build.query<CompanyAccount[], void>( {
      query: () => ( {
        url: '/companies', method: 'get'
      } ),
      providesTags: [ 'companies' ]
    } ),
    createCompany: build.mutation<void, CreateOrganizationFields>( {
      query: arg => ( {
        url: '/companies',
        method: 'post',
        data: arg
      } ),
      invalidatesTags: [ 'companies' ]
    } ),
    deleteCompany: build.mutation<void, string>( {
      query: id => ( {
        url: `/companies/${id}`,
        method: 'delete'
      } ),
      invalidatesTags: ( res, err ) => err ? [] : [ 'companies' ]
    } ),
    getUsers: build.query<UserAccount[], void>( {
      query: () => ( {
        url: '/users', method: 'get'
      } ),
      providesTags: [ 'users' ]
    } ),
    createUser: build.mutation<void, CreateUserFields>( {
      query: fields => ( {
        url: '/users',
        method: 'post',
        data: fields
      } ),
      invalidatesTags: [ 'users' ]
    } ),
    deleteUser: build.mutation<void, string>( {
      query: id => ( {
        url: `/users/${id}`,
        method: 'delete'
      } ),
      invalidatesTags: [ 'users' ]
    } ),
    generateDummyData: build.mutation<DummyDataGenerationResponse, DummyDataBody>( {
      query: fields => ( {
        url: '/dummy',
        method: 'post',
        data: fields
      } ),
      invalidatesTags: [ 'companies', 'users' ]
    } ),
    getSubmissions: build.query<CulturalAssessmentSubmission, GetSubmissionsBody>( {
      query: data => ( {
        url: '/assessments/submissions',
        method: 'post',
        data
      } )
    } ),
    getAssessments: build.query<CulturalAssessment[], GetAssessmentsBody>( {
      query: data => ( {
        url: '/assessments',
        method: 'get',
        params: data
      } ),
      providesTags: ( res, err, arg ) => err ? [] : [ { type: 'assessments', id: arg.companyId || 'all' } ]
    } ),
    getAssessmentAttributes: build.query<AssessmentAttribute[], void>( {
      query: () => ( {
        url: '/assessments/attributes',
        method: 'get'
      } ),
      providesTags: [ 'attributes' ]
    } ),
    getFitTechSubmissions: build.query<FitTechSubmission[], GetFitTechSubmissionsBody>( {
      query: data => ( {
        url: '/assessments/fitTech/submissions',
        method: 'post',
        data
      } ),
      providesTags: [ 'fitTechSubmissions' ]
    } ),
    getFitTechQuestions: build.query<FitTechQuestion[], void>( {
      query: () => ( {
        url: '/assessments/fitTech/questions',
        method: 'get'
      } ),
      providesTags: [ 'fitTechQuestions' ]
    } ),
    getFitTechSecondaryQuestions: build.query<FitTechSecondaryQuestion[], void>( {
      query: () => ( {
        url: '/assessments/fitTech/secondaryQuestions',
        method: 'get'
      } ),
      providesTags: [ 'fitTechSecondaryQuestions' ]
    } ),
    generateFitTechDocument: build.mutation<void, string>( {
      query: submissionId => ( {
        url: '/assessments/fitTech/generate',
        method: 'post',
        data: { submissionId }
      } ),
      invalidatesTags: ( res, err ) => err ? [] : [ 'fitTechSubmissions' ]
    } ),
    changeFitTechStatus: build.mutation<void, ChangeFitTechStatusBody & {companyId: string}>( {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      query: ( { companyId, ...data } ) => ( {
        url: '/assessments/fitTech/status',
        method: 'put',
        data
      } ),
      invalidatesTags: ( res, err, arg ) => err ? [] : [ { type: 'assessments', id: arg.companyId || 'all' } ]
    } )
  } )
} );

export const {
  useGetCompaniesQuery,
  useCreateCompanyMutation,
  useDeleteCompanyMutation,
  useGetUsersQuery,
  useCreateUserMutation,
  useDeleteUserMutation,
  useGenerateDummyDataMutation,
  useGetSubmissionsQuery,
  useGetAssessmentsQuery,
  useGetAssessmentAttributesQuery,
  useGetFitTechSubmissionsQuery,
  useGetFitTechQuestionsQuery,
  useGetFitTechSecondaryQuestionsQuery,
  useGenerateFitTechDocumentMutation,
  useChangeFitTechStatusMutation,
} = mainApi;