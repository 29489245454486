import { useCallback, useMemo, useState } from 'react';
import '../../../assets/css/forms/LabeledTextInput.css';

interface LabeledTextInputProps {
  label: string,
  type?: 'text' | 'email' | 'url' | 'number',
  disabled?: boolean,
  onChange: ( value: string ) => void,
  value: string
}

export const LabeledTextInput = ( { label, type='text', disabled=false, onChange, value } : LabeledTextInputProps ) => {

  const [ focused, setFocused ] = useState( false );

  const onBlur = useCallback( () => {
    setFocused( false );
  }, [ setFocused ] );

  const name = useMemo( () => (
    randomString( 10 )
  ), [] );

  const onFocus = () => setFocused( true );

  const onInputChange : React.ChangeEventHandler<HTMLInputElement> = event => {
    onChange( event.target.value );
  };

  let labelClassName = '';
  if ( focused || value ) labelClassName += 'focused';

  return (
    <div className="labeled-text-input">
      <label htmlFor={name} className={labelClassName}>{label}</label>
      <input 
        type={type} 
        name={name} 
        id={name} 
        onBlur={onBlur} 
        onFocus={onFocus}
        onChange={onInputChange} 
        value={value}
        disabled={disabled}
      />
    </div>
  );
};

function randomString( length: number ) {
  let result           = '';
  const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for ( let i = 0; i < length; i++ ) {
    result += characters.charAt( Math.floor( Math.random() * charactersLength ) );
  }
  return result;
}