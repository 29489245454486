import { useState } from 'react';
import { RiArrowUpSLine, RiCheckDoubleLine, RiCloseLine, RiSubtractLine } from 'react-icons/ri';
import { useFitTechQuestions } from '../../../hooks';
import { FitTechSubmission } from '../../../services/models';


export interface SubmissionAnswersProps {
  submission: FitTechSubmission
}


export const SubmissionAnswers = ( { submission } : SubmissionAnswersProps ) => {

  const questions = useFitTechQuestions();
  const [ isCollapsed, setCollapsed ] = useState( true );

  const toggleCollapsed = ( collapsed: boolean ) => () => setCollapsed( collapsed );

  return (
    <>
      <div 
        className={[
          'toggle-collapse',
          isCollapsed ? 'collapsed' : undefined
        ].join( ' ' )}
        onClick={toggleCollapsed( !isCollapsed )}
      >
        <RiArrowUpSLine />
      </div>
      <ul className={[
        'answers',
        isCollapsed ? 'collapsed' : undefined
      ].join( ' ' )}>
        {submission.answers.map( answer => {
          
          if ( !questions ) return null;
          const question = questions[answer.questionId];

          const answerPolarity = answer.answer === 'agreed' ? '+' : '-';

          return (
            <li>
              <p className='question'>
                {question.wording}
              </p>
              <p className={[
                'answer',
                answer.answer === 'neither'
                  ? undefined
                  : question.polarity === answerPolarity ? 'positive' : 'negative'
              ].join( ' ' )}>
                {
                  answer.answer === 'neither'
                  ? <RiSubtractLine />
                  : (
                    question.polarity === answerPolarity
                    ? <RiCheckDoubleLine />
                    : <RiCloseLine />
                    )
                }
                {answer.answer}
              </p>
            </li>
          );
        } )}
      </ul>
    </>
  );
};