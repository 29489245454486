import { useState } from 'react';
import { RiArrowUpSLine, RiCheckDoubleLine, RiCloseLine, RiSubtractLine } from 'react-icons/ri';
import { useFitTechSecondaryQuestions } from '../../../hooks';
import { SubmissionAnswersProps } from './SubmissionAnswers';



export const SubmissionSecondaryAnswers = ( { submission } : SubmissionAnswersProps ) => {

  const questions = useFitTechSecondaryQuestions();
  const [ isCollapsed, setCollapsed ] = useState( true );

  const toggleCollapsed = ( collapsed: boolean ) => () => setCollapsed( collapsed );

  return (
    <>
      <div 
        className={[
          'toggle-collapse',
          isCollapsed ? 'collapsed' : undefined
        ].join( ' ' )}
        onClick={toggleCollapsed( !isCollapsed )}
      >
        <RiArrowUpSLine />
      </div>
      <ul className={[
        'answers',
        isCollapsed ? 'collapsed' : undefined
      ].join( ' ' )}>
        {submission.secondaryAnswers.map( answer => {
          
          if ( !questions ) return null;
          const question = questions[answer.questionId];

          const answerPolarity = answer.answer === 'positive' ? '+' : '-';

          const isNeutral = answer.answer === 'both';

          return (
            <li>
              <p className='question'>
                {question.wording}
                <span className='variants'>{question.positive} | {question.negative}</span>
              </p>
              <p className={[
                'answer',
                isNeutral
                  ? undefined
                  : question.polarity === answerPolarity ? 'positive' : 'negative'
              ].join( ' ' )}>
                {
                  isNeutral
                  ? <RiSubtractLine />
                  : (
                    question.polarity === answerPolarity
                    ? <RiCheckDoubleLine />
                    : <RiCloseLine />
                    )
                }
                {
                  isNeutral
                    ? 'Both'
                    : (
                      answer.answer === 'positive'
                        ? question.positive
                        : question.negative
                    )
                }
              </p>
            </li>
          );
        } )}
      </ul>
    </>
  );
};