import { useState } from 'react';
import { useGetCompaniesQuery } from '../../../services/mainApi';
import { Select } from '../../ui/Controls/Select';
import './AssessmentsScreen.sass';

export interface AssessmentsFiltersValues {
  company?: string
}

export interface AssessmentsFiltersProps {
  onFilter: ( filter: AssessmentsFiltersValues ) => void
}

export const AssessmentsFilters = ( { onFilter } : AssessmentsFiltersProps ) => {

  const companies = useGetCompaniesQuery();

  const [ company, setCompany ] = useState<string>();

  const onFilterClick = () => {
    onFilter( {
      company
    } );
  };


  return (
    <div className="assessment-filters">
      <Select
        placeholder='Company'
        options={new Map(
          ( companies.data || [] ).map( company => [ company._id, company.name ] ).concat( [ 
            [ 'all', 'All Companies' ]
          ] ) as [ [string, string] ]
        )}
        onChange={setCompany}
      />
      <button 
        className="button button-click-effect"
        onClick={onFilterClick}
      >
        Filter
      </button>
    </div>
  );
};