import { CulturalAssessmentSubmission } from '../../../services/models';
import { Modal } from '../../modal/modalBase/Modal';
import { AttributeItem } from './AttributeItem';



export interface SubmissionModalProps {
  submission?: CulturalAssessmentSubmission,
  onClose: () => void,
  isOpen: boolean
}


export const SubmissionModal = ( { isOpen, submission, onClose } : SubmissionModalProps ) => {
  
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={submission ? `${submission.name}` : ''}
    >
      {submission ? (
        <div className="submission-modal">
          <section>
            <h1>Essential</h1>
            <ul>
              {submission.essential.map( attributeId => (
                <AttributeItem attributeId={attributeId} key={attributeId} />
              ) )}
            </ul>
          </section>
          <section>
            <h1>Nice To Have</h1>
            <ul>
              {submission.nice.map( attributeId => (
                <AttributeItem attributeId={attributeId} key={attributeId} />
              ) )}
            </ul>
          </section>
          <section>
            <h1>Not Essential</h1>
            <ul>
              {submission.notEssential.map( attributeId => (
                <AttributeItem attributeId={attributeId} key={attributeId} />
              ) )}
            </ul>
          </section>
        </div>
      ) : null}
    </Modal>
  );
};