import { useFormikContext } from 'formik';
import { Select, SelectProps } from '../ui/Controls/Select';

export type SelectFieldProps<IsMulti extends boolean = false> = Omit<SelectProps<IsMulti>, 'onChange' | 'defaultValue'> & {
  name: string
}

export const SelectField = ( { name, ...selectProps } : SelectFieldProps ) => {

  const { initialValues, setFieldValue } = useFormikContext();

  const onChange = ( value: string ) => {
    setFieldValue( name, value );
  };
  
  return (
    <Select 
      {...selectProps}
      onChange={onChange}
      defaultValue={( initialValues as Record<string, string> )[name]}
      isMulti={false}
    />
  );
};