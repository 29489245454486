import { useFormikContext } from 'formik';
import { RiAddFill, RiCloseLine } from 'react-icons/ri';
import { DummyDataBody } from '../../../validation/dummyDataValidation';
import { CheckBox } from '../../ui/Controls/CheckBox';
import { LabeledTextInput } from '../../ui/Controls/LabeledTextInput';


export const AssessmentField = () => {

  const { values, setFieldValue } = useFormikContext();
  
  const assessments = ( values as Record<string, DummyDataBody['assessments']> )['assessments'];

  const updateAssessment = ( index: number, assessment: typeof assessments[number] ) => {
    setFieldValue( 'assessments', [
      ...assessments.slice( 0, index ),
      assessment,
      ...assessments.slice( index+1 )
    ] );
  };
  
  const onAmountChange = ( index: number ) => ( value: string ) => {
    const assessment = assessments[index];
    updateAssessment( index, { ...assessment, submissionsAmount: +value } );
  };
  const onTargetAmountChange = ( index: number ) => ( value: string ) => {
    const assessment = assessments[index];
    updateAssessment( index, { ...assessment, targetSubmissionsAmount: +value } );
  };
  const onFitTechUnlockedChange = ( index: number ) => ( value: boolean ) => {
    const assessment = assessments[index];
    updateAssessment( index, { ...assessment, fitTech: { ...assessment.fitTech, unlocked: value } } );
  };
  const onFitTechAmountChange = ( index: number ) => ( value: string ) => {
    const assessment = assessments[index];
    updateAssessment( index, { ...assessment, fitTech: { ...assessment.fitTech, submissionsAmount: +value } } );
  };

  const addAssessment = () => {
    setFieldValue( 'assessments', assessments.concat( {
      submissionsAmount: 5,
      targetSubmissionsAmount: 7,
      fitTech: {
        unlocked: false,
        submissionsAmount: 7
      }
    } ) );
  };

  const removeAssessment = ( index: number ) => () => {
    setFieldValue( 'assessments', [
      ...assessments.slice( 0, index ),
      ...assessments.slice( index + 1 )
    ] );
  };

  return (
    <div className="assessments-field">
      {assessments.map( ( assessmentData, index ) => (
        <div className="assessment" key={index}>
          <div className="aside">
            <span className='number'>
              {index + 1}
            </span>
            <div className="remove" onClick={removeAssessment( index )}>
              <RiCloseLine size={18} />
            </div>
          </div>
          <div className='fields'>
            <LabeledTextInput
              label='Amount of submissions to generate'
              type='number'
              onChange={onAmountChange( index )}
              value={assessmentData.submissionsAmount+''}
            />
            <LabeledTextInput
              label='Target amount of submissions for that survey'
              type='number'
              onChange={onTargetAmountChange( index )}
              value={assessmentData.targetSubmissionsAmount+''}
            />
            <CheckBox
              checked={assessmentData.fitTech.unlocked}
              label='Is FitTech unlocked for that survey?'
              onChange={onFitTechUnlockedChange( index )}
            />
            {assessmentData.fitTech.unlocked && (
              <LabeledTextInput
              label='Amount of FitTech submissions to generate'
              type='number'
              onChange={onFitTechAmountChange( index )}
              value={assessmentData.fitTech.submissionsAmount+''}
              />
            )}
          </div>
        </div>
      ) )}
      <div className="add-assessment box" onClick={addAssessment}>
        <RiAddFill size={18} />
      </div>
    </div>
  );
};