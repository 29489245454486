import { CSSProperties } from 'react';


export interface ModalBodyProps {
  children: React.ReactNode,
  style?: CSSProperties
}

export const ModalBody = ( { children, style } : ModalBodyProps ) => (
  <div className="modal-body" style={style}>
    {children}
  </div>
);